import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "total", "subCost", "balance", "surcharge", "saleTotal" ]



  setTotal(){
    const saleTotal = parseFloat(this.saleTotalTarget.innerHTML);

    const returnedTotal = this.subCostTargets.reduce((total, subcost) => {
      return total + parseFloat(subcost.textContent);
    }, 0);

    
    this.totalTarget.textContent = returnedTotal;

    const surcharge = parseFloat(this.surchargeTarget.value);

    const balance = saleTotal - returnedTotal - surcharge;

    this.balanceTarget.textContent = balance;
  }
}
