import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "phoneField", "csvFileSection",
    "submitButton","billerField", "priceGroupField",
    "recipientType", "dateRangeField",
    "billerDateRangeField","priceGroupDateRangeField",
    "billerPriceGroupField", "billerPriceGroupDateRangeField",
    "brandField", "productField",
    "brandBiller", "productBiller",
    "brandPrice", "productPrice",
    "productDateField", "brandDateField"
  ];

  togglePhoneField(event) {
    if (event.target.checked) {
      this.phoneFieldTarget.classList.add("d-none");
      this.recipientTypeTarget.classList.add("d-none");
      this.submitButtonTarget.classList.add("d-none");
      this.csvFileSectionTarget.classList.remove("d-none");
    } else {
      this.phoneFieldTarget.classList.remove("d-none");
      this.recipientTypeTarget.classList.remove("d-none");
      this.submitButtonTarget.classList.remove("d-none");
      this.csvFileSectionTarget.classList.add("d-none");
    }
  }

  toggleField() {
    const recipientType = this.element.querySelector("select[name='message[recipient_type]']").value;
    this.phoneFieldTarget.style.display = recipientType === "customer" ? "block" : "none";
    this.billerFieldTarget.style.display = recipientType === "biller" ? "block" : "none";
    this.priceGroupFieldTarget.style.display = recipientType === "price_group" ? "block" : "none";
    this.dateRangeFieldTarget.style.display = recipientType === "date_range" ? "block" : "none";
    this.billerDateRangeFieldTarget.style.display = recipientType === "biller_date_range" ? "block" : "none";
    this.priceGroupDateRangeFieldTarget.style.display = recipientType === "price_group_date_range" ? "block" : "none";
    this.billerPriceGroupFieldTarget.style.display = recipientType === "biller_price_group" ? "block" : "none";
    this.billerPriceGroupDateRangeFieldTarget.style.display = recipientType === "biller_price_group_date_range" ? "block" : "none";
  }

  toggleFilterField() {
    const filterType = this.element.querySelector("input[name='message[filter_type]']:checked")?.value;
    this.brandFieldTarget.style.display = filterType === "brand" ? "block" : "none";
    this.productFieldTarget.style.display = filterType === "product" ? "block" : "none";
  }

  toggleDateFilterField() {
    const filterType = this.element.querySelector("input[name='message[date_filter_type]']:checked")?.value;
    this.brandBillerTarget.style.display = filterType === "brand" ? "block" : "none";
    this.productDateFieldTarget.style.display = filterType === "product" ? "block" : "none";
  }

  toggleBillerFilterField() {
    const filterType = this.element.querySelector("input[name='message[biller_filter_type]']:checked")?.value;
    this.brandBillerTarget.style.display = filterType === "brand" ? "block" : "none";
    this.productBillerTarget.style.display = filterType === "product" ? "block" : "none";
  }

  togglePriceFilterField() {
    const filterType = this.element.querySelector("input[name='message[price_filter_type]']:checked")?.value;
    this.brandPriceTarget.style.display = filterType === "brand" ? "block" : "none";
    this.productPriceTarget.style.display = filterType === "product" ? "block" : "none";
  }

  async updateCustomerCount() {
    const recipientType = this.element.querySelector("select[name='message[recipient_type]']").value;
    const filters = this.getSelectedFilters(recipientType);

    try {
      const response = await fetch(`/count_messages?recipient_type=${recipientType}&filters=${JSON.stringify(filters)}`);
      const data = await response.json();
      this.updateCustomerCountUI(data.count);
    } catch (error) {
      console.error('Error fetching customer count:', error);
    }
  }

  getSelectedFilters(recipientType) {
    const filters = {};

    switch (recipientType) {
      case 'biller':
        filters.biller_id = this.element.querySelector("select[name='message[biller_id]']").value;
        filters.biller_filter_type = this.element.querySelector("input[name='message[biller_filter_type]']:checked")?.value;
        if (filters.biller_filter_type === 'brand') {
          filters.brand_id = this.element.querySelector("select[name='message[brand_id]']").value;
        } else if (filters.biller_filter_type === 'product') {
          filters.product_ids = Array.from(this.element.querySelectorAll("select[name='message[product_ids][]']")).map(el => el.value);
        }
        break;
      case 'price_group':
        filters.single_price_group_id = this.element.querySelector("select[name='message[single_price_group_id]']").value;
        filters.price_filter_type = this.element.querySelector("input[name='message[price_filter_type]']:checked")?.value;
        if (filters.price_filter_type === 'brand') {
          filters.brand_id = this.element.querySelector("select[name='message[brand_id]']").value;
        } else if (filters.price_filter_type === 'product') {
          filters.product_ids = Array.from(this.element.querySelectorAll("select[name='message[product_ids][]']")).map(el => el.value);
        }
        break;
      case 'date_range':
        filters.date_range_start_date = this.element.querySelector("input[name='message[date_range_start_date]']").value;
        filters.date_range_end_date = this.element.querySelector("input[name='message[date_range_end_date]']").value;
        break;
      case 'biller_date_range':
        filters.biller_id = this.element.querySelector("select[name='message[biller_id]']").value;
        filters.biller_date_range_start_date = this.element.querySelector("input[name='message[biller_date_range_start_date]']").value;
        filters.biller_date_range_end_date = this.element.querySelector("input[name='message[biller_date_range_end_date]']").value;
        break;
      case 'price_group_date_range':
        filters.price_group_id = this.element.querySelector("select[name='message[price_group_id]']").value;
        filters.price_group_date_range_start_date = this.element.querySelector("input[name='message[price_group_date_range_start_date]']").value;
        filters.price_group_date_range_end_date = this.element.querySelector("input[name='message[price_group_date_range_end_date]']").value;
        break;
      case 'biller_price_group':
        filters.group_biller_id = this.element.querySelector("select[name='message[group_biller_id]']").value;
        filters.biller_price_id = this.element.querySelector("select[name='message[biller_price_id]']").value;
        break;
      case 'biller_price_group_date_range':
        filters.date_biller_id = this.element.querySelector("select[name='message[date_biller_id]']").value;
        filters.biller_price_date_id = this.element.querySelector("select[name='message[biller_price_date_id]']").value;
        filters.biller_price_group_date_range_start_date = this.element.querySelector("input[name='message[biller_price_group_date_range_start_date]']").value;
        filters.biller_price_group_date_range_end_date = this.element.querySelector("input[name='message[biller_price_group_date_range_end_date]']").value;
        filters.date_filter_type = this.element.querySelector("input[name='message[date_filter_type]']:checked")?.value;
        if (filters.date_filter_type === 'brand') {
          filters.brand_id = this.element.querySelector("select[name='message[brand_id]']").value;
        } else if (filters.date_filter_type === 'product') {
          filters.product_ids = Array.from(this.element.querySelectorAll("select[name='message[product_ids][]']")).map(el => el.value);
        }
        break;
    }

    return filters;
  }

  updateCustomerCountUI(count) {
    const countElement = this.element.querySelector('#customer-count');
    if (countElement) {
      countElement.textContent = count;
    }
  }

}