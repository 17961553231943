import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

  static targets = [ "status", "delivery", "overlay" ]
  static values = { completeStatus: String }

  connect() {
    this.checkCompleteStatus();
    this.modal = new Modal(this.element)
    this.modal.hide()
    this.showDeliveryAttributes();
  }

  checkCompleteStatus() {
    const saleCompleteStatus = this.completeStatusValue;
    if (saleCompleteStatus === "pending-in-store") {
      this.showOverlay();
    } else {
      this.hideOverlay();
    }
  }

  showOverlay() {
    this.overlayTarget.classList.remove("d-none");
  }

  hideOverlay() {
    this.overlayTarget.classList.add("d-none");
  }

  dismissModal() {
    this.hideOverlay();
  }

  showDeliveryAttributes(){
    if (this.statusTarget.value === "delivery") {
      this.deliveryTarget.classList.remove("d-none")
    } else {
      this.deliveryTarget.classList.add("d-none")
    }
  }
}
